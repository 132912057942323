import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  cart: localStorage.getItem('_cart')
    ? JSON.parse(localStorage.getItem('_cart'))
    : []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_CART:
      return {
        ...state,
        cart: action.cart
      };

    default:
      return state;
  }
}
