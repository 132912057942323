import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  boards: {},
  xIsNext: {},
  winner: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_TIC_TAC_TOE_BOARD:
      return {
        ...state,
        boards: {
          ...state.boards,
          [action.ticTacToeKey]: action.board
        }
      };

    case actionsTypes.SET_TIC_TAC_TOE_X_IS_NEXT:
      return {
        ...state,
        xIsNext: {
          ...state.xIsNext,
          [action.ticTacToeKey]: action.xIsNext
        }
      };

    case actionsTypes.SET_TIC_TAC_TOE_WINNER:
      return {
        ...state,
        winner: {
          ...state.xIsNext,
          [action.ticTacToeKey]: action.winner
        }
      };

    default:
      return state;
  }
}
