import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  id: null,
  talking_index: 0,
  talkings: [
    {
      id: '',
      avatar: { url: '', id: '' },
      letra: 'a',
      falas: [
        {
          audio_url: '',
          opcoes_resposta: [{ texto: '' }],
          ordem_fala: 1,
          traducao: ''
        }
      ]
    },
    {
      id: '',
      avatar: { url: '', id: '' },
      letra: 'b',
      falas: [
        {
          audio_url: '',
          opcoes_resposta: [{ texto: '' }],
          ordem_fala: 2,
          traducao: ''
        }
      ]
    }
  ],
  numero_personagens: 0,
  total_falas: 2
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_AVATAR:
      return {
        ...state,
        talkings: [
          ...state.talkings.slice(0, state.talking_index),
          {
            ...state.talkings[state.talking_index],
            avatar: action.avatar
          },
          ...state.talkings.slice(state.talking_index + 1)
        ]
      };
    case actionsTypes.SET_TALKING_INDEX:
      return {
        ...state,
        talking_index: action.talking_index
      };

    case actionsTypes.SET_FALAS_TALKING:
      return {
        ...state,
        talkings: [
          ...state.talkings.slice(0, state.talking_index),
          {
            ...state.talkings[state.talking_index],
            falas: action.falas
          },
          ...state.talkings.slice(state.talking_index + 1)
        ],
        total_falas: action.adicionando
          ? state.total_falas + 1
          : action.removendo
          ? state.total_falas - 1
          : state.total_falas
      };

    case actionsTypes.SET_OPCOES_FALA_TALKING:
      return {
        ...state,
        talkings: [
          ...state.talkings.slice(0, state.talking_index),
          {
            ...state.talkings[state.talking_index],
            falas: [
              ...state.talkings[state.talking_index].falas.slice(
                0,
                action.indexFala
              ),
              {
                ...state.talkings[state.talking_index].falas[action.indexFala],
                opcoes_resposta: action.opcoes
              },
              ...state.talkings[state.talking_index].falas.slice(
                action.indexFala + 1
              )
            ]
          },
          ...state.talkings.slice(state.talking_index + 1)
        ]
      };

    case actionsTypes.SET_PRACTICE:
      return { ...action.practice };

    case actionsTypes.SET_PRACTICE_DEFAULT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
