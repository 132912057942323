import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  cardsChallenge: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_CARD_CHALLENGE:
      return {
        ...state,
        cardsChallenge: {
          ...state.cardsChallenge,
          [action.cardChallengeKey]: {
            show: action.showCardChallenge,
            text: action.text
          }
        }
      };

    case actionsTypes.RESET_CARDS_CHALLENGE:
      return INITIAL_STATE;

    default:
      return state;
  }
}
