import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  players: [],
  playerSelected: 0,
  endGame: false,
  pontos: 100,
  descontarPontos: true,
  podeVoltar: false,
  placarPlayerAnterior: 0,
  totems: [],
  playersCount: 0
};

export default function(state = INITIAL_STATE, action) {
  let players = [];
  let playerSelected = 0;
  let placarPlayerAnterior = 0;
  switch (action.type) {
    case actionsTypes.SET_PLAYERS_PLACAR:
      return { ...state, players: action.players };

    case actionsTypes.SET_PLAYER_SELECTED_PLACAR:
      return { ...state, playerSelected: action.playerSelected };

    case actionsTypes.NEXT_PLAYER_PLACAR:
      players = state.players;
      placarPlayerAnterior = players[state.playerSelected].score;

      if (action.acertou) {
        players[state.playerSelected] = {
          ...players[state.playerSelected],
          score:
            players[state.playerSelected].score +
            (isNaN(state.pontos) ? 0 : state.pontos)
        };
      } else {
        if (state.descontarPontos) {
          players[state.playerSelected] = {
            ...players[state.playerSelected],
            score:
              players[state.playerSelected].score -
                (isNaN(state.pontos) ? 0 : state.pontos) <=
              0
                ? 0
                : players[state.playerSelected].score -
                  (isNaN(state.pontos) ? 0 : state.pontos)
          };
        }
      }

      playerSelected = 0;

      if (state.playerSelected < state.players.length - 1)
        playerSelected = state.playerSelected + 1;

      const diceElement = document.getElementsByClassName('dice');
      const isDice = diceElement && diceElement.length;

      return {
        ...state,
        players,
        playerSelected,
        placarPlayerAnterior,
        podeVoltar: true,
        pontos: !isDice ? state.pontos: 0
      };

    case actionsTypes.PREVIOUS_PLAYER_PLACAR:
      if (!state.podeVoltar) return state;

      players = state.players;

      if (players[state.playerSelected - 1]) {
        players[state.playerSelected - 1].score = state.placarPlayerAnterior;

        return {
          ...state,
          players,
          playerSelected: state.playerSelected - 1,
          podeVoltar: false,
          placarPlayerAnterior: 0
        };
      } else {
        players[players.length - 1].score = state.placarPlayerAnterior;

        return {
          ...state,
          players,
          playerSelected: players.length - 1,
          podeVoltar: false,
          placarPlayerAnterior: 0
        };
      }

    case actionsTypes.SET_END_GAME_PLACAR:
      return {
        ...state,
        endGame: action.endGame,
        pontos: INITIAL_STATE.pontos
      };

    case actionsTypes.SET_PLAYER_SCORE:
      return { ...state, pontos: action.pontos };

    case actionsTypes.RESET_PLAYER_SCORE:
      return { ...state, pontos: INITIAL_STATE.pontos };

    case actionsTypes.SET_DESCONTAR_PONTOS:
      return { ...state, descontarPontos: action.descontarPontos };

    case actionsTypes.SET_STATE:
      if (action.state?.PlacarReducer) return action.state.PlacarReducer;
      return state;

    case actionsTypes.SET_TOTEMS:
      return { ...state, totems: action.totems };

    case actionsTypes.SET_PLAYERS_COUNT:
      return { ...state, playersCount: action.playersCount };

    case actionsTypes.RESET_STATE:
      return INITIAL_STATE;

    case actionsTypes.SET_STATE_SAVE:
      return {
        ...state,
        players: action.state.players,
        playerSelected: action.state.playerSelected,
        totems: action.state.totems,
        placarPlayerAnterior: action.state.placarPlayerAnterior,
        podeVoltar: action.state.podeVoltar,
        playersCount: action.state.playersCount,
        pontos: action.state.pontos,
        descontarPontos: action.state.descontarPontos,
        endGame: action.state.endGame,
      };

    default:
      return state;
  }
}
