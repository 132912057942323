import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  pincelColor: '#00f',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_PINCEL_COLOR:
      return {
        ...state,
        pincelColor: action.pincelColor,
      };

    default:
      return state;
  }
}
