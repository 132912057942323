import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  totems: {},
  totemsLoad: {},
  totemPositionStudent: null,
  totemPositionTeacher: null,
  updateTotem: null,
  change: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.ADD_TOTEM:
      return {
        ...state,
        totems: {
          ...state.totems,
          ['totem_' + action.totem.blockIndex]: action.totem
        },
        totemsLoad: {
          ...state.totemsLoad,
          ['totem_' + action.totem.blockIndex]: action.totem
        },
        change: !state.change
      };

    case actionsTypes.SET_TOTEM:
      return {
        ...state,
        totemsLoad: {  ...state.totemsLoad, ['totem_'+action.totem.blockIndex]: action.totem },
        totems: {
          ['totem_' + action.totem.blockIndex]: action.totem
        },
        change: !state.change
      };

    case actionsTypes.SET_TOTEM_POSITION_STUDENT:
      return { ...state, totemPositionStudent: action.totem };

    case actionsTypes.SET_TOTEM_POSITION_TEACHER:
      return { ...state, totemPositionTeacher: action.totem };

    case actionsTypes.SET_STATE:
      if (action.state?.TotemReducer) return action.state.TotemReducer;
      return state;

    case actionsTypes.SET_UPDATE_TOTEM:
      return { ...state, updateTotem: action.totem };

    default:
      return state;
  }
}
