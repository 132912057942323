import { takeLatest, select } from 'redux-saga/effects';
import actionsTypes from './constants/ActionsTypes';
import {parse, stringify} from 'flatted' ;

function* mySaga() {
  yield takeLatest('*', onAllAction);
}

function* onAllAction(action) {
  if (action.fromStudent) return;
  if (action.fromTeacher) return;

  const state = yield select();

  const teacher = state.SocketReducer.teacher;

  if (!teacher || !teacher.getStreamManager()) {
    return;
  }

  if (
    action.type === actionsTypes.SET_SLIDE ||
    action.type === actionsTypes.ADD_PARTICIPANT ||
    action.type === actionsTypes.SET_SIZE_CONTENT ||
    action.type === actionsTypes.SET_IS_MUTE_ALL ||
    action.type === actionsTypes.SET_IS_PREVIOUS_AUDIO_STATUS ||
    action.type === actionsTypes.SET_CONNECTED_USERS ||
    action.type === actionsTypes.SET_LAST_AUDIO_PLAYED ||
    action.type === actionsTypes.SET_STATE_PLAY_AUDIOS ||
    action.type === actionsTypes.SET_IS_AUDIO_PLAYING ||
    action.type === actionsTypes.SET_CHANGE_CLICK_NEXT_SLIDE ||
    action.type === actionsTypes.SET_UPDATE_TOTEM ||
    action.type === actionsTypes.DELETE_ALL_BLOCK ||
    action.type === actionsTypes.TOGGLE_SHOW_BOARD ||
    action.type === actionsTypes.SET_BOARD_TOP_DISTANCE ||
    action.type === actionsTypes.SET_SIZE_BOARD ||
    action.type === actionsTypes.SET_CONNECTION_INTERNET ||
    action.type === actionsTypes.SET_ID_QUESTAO_SLIDE ||
    action.type === actionsTypes.SET_SHOW_CONFIG_MICROPHONE ||
    action.type === actionsTypes.SET_MICROPHONE ||
    action.type === actionsTypes.SET_IS_LIVEBRIDGE_LOADED ||
    action.type === actionsTypes.SET_TEACHER ||
    action.type === actionsTypes.UPDATE_BLOCK_HISTORY ||
    action.type === actionsTypes.SET_CLICK_INDEX_REMOTE ||
    action.type === actionsTypes.ADD_BLOCK_TEXT ||
    action.type === actionsTypes.SET_BLOCK_POSITION ||
    action.type === actionsTypes.SET_ARROW_BLOCK_POSITION ||
    action.type === actionsTypes.SET_BLOCK_SIZE ||
    action.type === actionsTypes.SET_BLOCK_INDEX ||
    action.type === actionsTypes.ADD_BLOCK_SELECTED ||
    action.type === actionsTypes.DELETE_BLOCK ||
    action.type === actionsTypes.DELETE_ALL_BLOCK ||
    action.type === actionsTypes.DELETE_SELECTED_BLOCK ||
    action.type === actionsTypes.ADD_BLOCK_TEXT ||
    action.type === actionsTypes.SET_HIDE_BLOCK_POSITION ||
    action.type === actionsTypes.SET_HIDE_BLOCK_SIZE ||
    action.type === actionsTypes.COPY_BLOCKS ||
    action.type === actionsTypes.PASTE_BLOCKS ||
    action.type === actionsTypes.RESET_BLOCKS_SELECTED ||
    action.type === actionsTypes.SET_CONTROL_IS_PRESSED ||
    action.type === actionsTypes.SET_IN_INPUT ||
    action.type === actionsTypes.SET_CONTENT ||
    action.type === actionsTypes.SET_HTML ||
    action.type === actionsTypes.SET_COLUMN_HTML ||
    action.type === actionsTypes.SET_COLUMN_CONTENT ||
    action.type === actionsTypes.UNDO ||
    action.type === actionsTypes.REDO ||
    action.type === actionsTypes.UPDATE_BLOCK_HISTORY ||
    action.type === actionsTypes.SET_IS_EXIT
  ) return;

  if (action.type === actionsTypes.SET_TOTEM &&  action.totem && action.totem.ignoreSend) {
    return;
  }

  if (action.type === actionsTypes.SET_PLAYER_SECONDS && !action.isSendSocket) {
    return;
  }

  if (action.type === actionsTypes.SET_PLAYER_SECONDS && action.isChangeSlider) {
    return;
  }


  if (action.type === actionsTypes.SET_INDICE_AUDIO && !action.isSendStudent) {
    return;
  }

  if (action.type === actionsTypes.CHANGE_IMAGE_CARD && !action.isSendStudent) {
    return;
  }

  if (!state.AulaReducer.isLive) return;
  if (!state.AulaReducer.room) return;

  const slideId = state.SlideReducer.slide.id;

  action['fromTeacher'] = true;

  const noSaveActions = [
    actionsTypes.SET_PLAY_BLOCK_KEY,
    actionsTypes.SET_STATE_PLAY_AUDIOS,
    actionsTypes.SET_IS_LISTEN_AUDIOS_STUDENT,
    actionsTypes.SET_PLAY_BLOCK_KEY,
    actionsTypes.SET_STATE_PLAY_AUDIOS,
    actionsTypes.SET_IS_LISTEN_AUDIOS_STUDENT,
    actionsTypes.SET_IS_PLAYING_STUDENT,
    actionsTypes.SET_IS_PLAYING_TEACHER,
    actionsTypes.SET_UPDATE_STUDENT_INDICE_AUDIO,
    actionsTypes.SET_TOTEM_POSITION_TEACHER,
    actionsTypes.SET_DICE_NUMBER,
    actionsTypes.SET_IS_ROLL_DICE_TEACHER,
    actionsTypes.SET_IS_ONLINE_TEACHER,
    actionsTypes.SET_PLAYER_SECONDS,
    actionsTypes.SET_CARD_ALEATORIO,
    actionsTypes.SET_SHOW_CARD_TOAST,
    actionsTypes.SET_LAST_CARD_SELECTED,
    actionsTypes.SET_INDICE_AUDIO,
    actionsTypes.CHANGE_CRONOMETRO,
  ];

  let isSaveAction = true;
  if (noSaveActions.includes(action.type)) {
    isSaveAction = false;
  }

  if (
    action.type === actionsTypes.SET_PLAY_BLOCK_KEY ||
    action.type === actionsTypes.SET_PLAYER_SECONDS ||
    action.type === actionsTypes.SET_IS_LISTEN_AUDIOS_STUDENT ||
    action.type === actionsTypes.SET_IS_PLAYING_STUDENT ||
    action.type === actionsTypes.SET_IS_PLAYING_TEACHER
  ) {
    console.log(action);
  }

  const data = {
    slideId,
    action,
    isSaveAction
  };

  teacher.getStreamManager().stream.session.signal({
    data: stringify(data),
    type: 'run-action',
  });

  let classRooms = {};
  if (localStorage.getItem('classRooms')) {
    classRooms = parse(localStorage.getItem('classRooms'));
  }

  const room = state.AulaReducer.room;
  if (slideId){
    classRooms[room] = { slideId }
    localStorage.setItem("classRooms", stringify(classRooms));
  }

  if (!slideId) {
    return;
  }

  let stateRoom = {};
  if (localStorage.getItem('stateRoom')) {
    stateRoom = parse(localStorage.getItem('stateRoom'));
  }

  if (!stateRoom[room] || stateRoom[room].slideId !== slideId) {
    stateRoom[room] = {
      slideId: slideId,
      lengthActions: 0,
      actions: {},
    };
  }

  if (action.type === "SET_STATE_PLAY_AUDIOS") {
    stateRoom[room].audioState = action.state;
  }

  if (slideId && isSaveAction) {
    const indexNewAction = stateRoom[room].lengthActions + 1;
    stateRoom[room].lengthActions = stateRoom[room].lengthActions + 1;
    stateRoom[room].actions = {
      ...stateRoom[room].actions,
      [indexNewAction]: action,
    };
  }

  localStorage.setItem("stateRoom", stringify(stateRoom));
}

export default mySaga;
