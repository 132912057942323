import { useEffect, useState } from 'react';
import apiLmsManager from '../services/apiLmsManager';

// import { Container } from './styles';

const offline = process.env.REACT_APP_AMBIENTE === 'OFFLINE';

function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  function cleanStorage() {
    localStorage.removeItem('@RockfellerToken');
    localStorage.removeItem('@RockfellerRefreshToken');
    localStorage.removeItem('@JoyToken');
    localStorage.removeItem('@JoyRefreshToken');
    localStorage.removeItem('STUDENT_NAME');
    localStorage.removeItem('LOGO');
  }

  useEffect(() => {
    setLoading(true);
    if (offline) {
      setLoading(false);
      return setIsAuthenticated(true);
    }

    apiLmsManager
      .get('/user')
      .then(async response => {
        setIsAuthenticated(true);
      })
      .catch(async error => {
        //refresh token
        if (
          error.response.status === 401 &&
          localStorage.getItem('@RockfellerRefreshToken')
        ) {
          try {
            const refreshToken = await apiLmsManager.post('/token/refresh', {
              refresh_token: localStorage.getItem('@RockfellerRefreshToken')
            });
            if (refreshToken.status === 401 || refreshToken.status === 403) {
              localStorage.removeItem('@RockfellerToken');
              setIsAuthenticated(false);
              //window.location.href = '/login';
              return;
            }
            localStorage.setItem('@RockfellerToken', refreshToken.data.token);
            localStorage.setItem(
              '@RockfellerRefreshToken',
              refreshToken.data.refresh_token
            );

            localStorage.removeItem('LOGO');
            setIsAuthenticated(true);
          } catch (err) {
            cleanStorage();
            setIsAuthenticated(false);
            //window.location.href = '/login';
          }

          return;
        }

        setIsAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [offline]);

  return {
    isAuthenticated,
    loading
  };
}

export default useAuth;
