import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  frases: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_FRASES_ORDENA:
      return { ...state, frases: action.frases };

    default:
      return state;
  }
}
