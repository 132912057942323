import React from 'react';
import { FaCheck, FaExclamation, FaInfo } from 'react-icons/fa';
import { Container } from './styles';

const AlertTemplate = ({ style, options, message, close }) => {
  return (
    <Container className="border-radius">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 40,
          textAlign: 'center',
          height: 40,
          borderRadius: '50%',
          background: '#F1BA0B',
          marginRight: 15,
          marginLeft: 15
        }}
      >
        {options.type === 'info' && (
          <FaInfo style={{ color: '#4a4a4a' }} size={18} />
        )}
        {options.type === 'success' && (
          <FaCheck style={{ color: '#4a4a4a' }} size={18} />
        )}
        {options.type === 'error' && (
          <FaExclamation style={{ color: '#4a4a4a' }} size={18} />
        )}
      </div>
      <div style={{ width: 320 }}>{message}</div>
    </Container>
  );
};

export default AlertTemplate;
