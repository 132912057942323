import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  scenarioIndex: null,
  maxImageShow: 1,
  imageIndex: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_SEQUENCE_IMAGES_GAME_STATE:
      return { ...state, ...action.state };

    default:
      return state;
  }
}
