import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  sheepGameResetFunction: () => {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_SHEEP_GAME_RESET_FUNCTION:
      return {
        ...state,
        sheepGameResetFunction: action.sheepGameResetFunction
      };

    default:
      return state;
  }
}
