import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  resetPrepositionGameFunction: () => {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_RESET_PREPOSITION_GAME_FUNCTION:
      return {
        ...state,
        resetPrepositionGameFunction: action.resetPrepositionGameFunction
      };

    default:
      return state;
  }
}
