import React from 'react';
import { Provider } from 'react-redux';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './components/AlertTemplate';

import GlobalStyle from './styles/global';
import Router from './routes';

import store from './store';
// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
};

function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <Router />
        <GlobalStyle />
      </Provider>
    </AlertProvider>
  );
}

export default App;
