import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  dices: {},
  isRollDiceTeacher: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_DICE_NUMBER:
      return {
        ...state,
        dices: {
          ...state.dices,
          [action.diceKey]: {
            diceNumber: action.diceNumber
          }
        }
      };

    case actionsTypes.SET_IS_ROLL_DICE_TEACHER:
      return { ...state, isRollDiceTeacher: action.isRollDiceTeacher };

    default:
      return state;
  }
}
