import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  slides: [
    {
      initial: true,
      backgroundImage: null,
      background: '#fff',
      blocks: {},
      extraWords: []
    }
  ],
  slideIndex: 0,
  blocksSelected: [],
  selectedCell: { line: null, column: null },
  toast: { show: false, message: '' },
  blockImage: null,
  copiedBlocks: JSON.parse(localStorage.getItem('copied_blocks')),
  isDragging: false,
  inInput: false,
  defaultBackground: { type: 'color', background: '#fff' },
  controlIsPressed: false
};

export default function(state = INITIAL_STATE, action) {
  let slides = [];
  let blocks;
  let history;
  let block;
  let copiedBlocks;

  function addHistory() {
    const stateAnterior = JSON.parse(JSON.stringify(state));
    let history = JSON.parse(localStorage.getItem('history'));
    if (history) {
      if (history.length < 10) {
        history.push(stateAnterior);
        localStorage.setItem('history', JSON.stringify(history));
      } else {
        history = history.slice(1);
        history.push(stateAnterior);
        localStorage.setItem('history', JSON.stringify(history));
      }
    } else {
      localStorage.setItem('history', JSON.stringify([stateAnterior]));
    }
  }

  function addHistoryRedo() {
    const stateAnterior = JSON.parse(JSON.stringify(state));
    let historyRedo = JSON.parse(localStorage.getItem('history_redo'));
    if (historyRedo) {
      if (historyRedo.length < 10) {
        historyRedo.push(stateAnterior);
        localStorage.setItem('history_redo', JSON.stringify(historyRedo));
      } else {
        historyRedo = historyRedo.slice(1);
        historyRedo.push(stateAnterior);
        localStorage.setItem('history_redo', JSON.stringify(historyRedo));
      }
    } else {
      localStorage.setItem('history_redo', JSON.stringify([stateAnterior]));
    }
  }

  function updateBlocksHistory() {
    blocks = state.slides[state.slideIndex].blocks;
    localStorage.setItem('blocks', JSON.stringify(blocks));
  }

  switch (action.type) {
    case actionsTypes.SET_BLOCK_POSITION:
      addHistory();
      slides = [];

      blocks = state.slides[state.slideIndex].blocks;
      blocks[action.index].position = {
        x: action.x,
        y: action.y
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.SET_ARROW_BLOCK_POSITION:
      addHistory();
      slides = [];
      blocks = state.slides[state.slideIndex].blocks;

      state.blocksSelected.forEach(blockIndex => {
        blocks = state.slides[state.slideIndex].blocks;
        blocks[blockIndex].position = {
          x:
            action.keyPressed === 'ArrowRight'
              ? blocks[blockIndex].position.x + 1
              : action.keyPressed === 'ArrowLeft'
              ? blocks[blockIndex].position.x - 1
              : blocks[blockIndex].position.x,
          y:
            action.keyPressed === 'ArrowUp'
              ? blocks[blockIndex].position.y - 1
              : action.keyPressed === 'ArrowDown'
              ? blocks[blockIndex].position.y + 1
              : blocks[blockIndex].position.y
        };
      });

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.SET_BLOCK_SIZE:
      addHistory();
      blocks = state.slides[state.slideIndex].blocks;

      blocks[action.index].size = {
        width: action.width,
        height: action.height
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };

    case actionsTypes.SET_BLOCK_INDEX:
      return { ...state, blocksSelected: [action.index] };

    case actionsTypes.ADD_BLOCK_SELECTED:
      if (state.blocksSelected.includes(action.index)) {
        const indexToRemove = state.blocksSelected.indexOf(action.index);
        return {
          ...state,
          blocksSelected: [
            ...state.blocksSelected.slice(0, indexToRemove),
            ...state.blocksSelected.slice(indexToRemove + 1)
          ]
        };
      }
      return {
        ...state,
        blocksSelected: [...state.blocksSelected, action.index]
      };

    case actionsTypes.DELETE_BLOCK:
      blocks = state.slides[state.slideIndex].blocks;
      delete blocks[action.blockKey];
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };
    case actionsTypes.DELETE_ALL_BLOCK:
      blocks = state.slides[state.slideIndex].blocks;
      Object.keys(blocks).map(key => {
          delete blocks[key];
          return null;
      });

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };

    case actionsTypes.DELETE_SELECTED_BLOCK:
      addHistory();
      blocks = state.slides[state.slideIndex].blocks;

      state.blocksSelected.forEach(blockIndex => {
        block = blocks[blockIndex];

        if (block && block.action && block.action === 'clickable') {
          Object.keys(blocks).map(key => {
            if (
              blocks[key] &&
              blocks[key].clickable &&
              blocks[key].clickable === blockIndex
            )
              delete blocks[key];
            return '';
          });
        }

        if (block.type === 'drop') {
          Object.keys(blocks).forEach(key => {
            if (
              blocks[key] &&
              blocks[key].dropable &&
              blocks[key].dropable === blockIndex
            )
              delete blocks[key];
          });
        }

        delete blocks[blockIndex];
      });

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides, blocksSelected: [] };

    case actionsTypes.ADD_BLOCK_TEXT:
      addHistory();
      blocks = {
        ...state.slides[parseInt(state.slideIndex)].blocks,
        [action.blockIndex]: action.block
      };
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks: blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.SET_BLOCK_IMAGE:
      return { ...state, blockImage: action.blockImage };

    case actionsTypes.SET_HIDE_BLOCK_POSITION:
      slides = [];

      blocks = state.slides[state.slideIndex].blocks;
      blocks[action.blockIndex].hideBlocks[action.hideBlockIndex].position = {
        x: action.x,
        y: action.y
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.SET_HIDE_BLOCK_SIZE:
      blocks = state.slides[state.slideIndex].blocks;

      blocks[action.blockIndex].hideBlocks[action.hideBlockIndex].size = {
        width: action.width,
        height: action.height
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };

    case actionsTypes.COPY_BLOCKS:
      blocks = state.slides[state.slideIndex].blocks;
      copiedBlocks = [];
      Object.keys(blocks).forEach(blockIndex => {
        if (state.blocksSelected.includes(blockIndex))
          copiedBlocks.push(blocks[blockIndex]);
      });
      localStorage.setItem('copied_blocks', JSON.stringify(copiedBlocks));
      return {
        ...state,
        copiedBlocks: copiedBlocks
      };

    case actionsTypes.PASTE_BLOCKS:
      copiedBlocks = JSON.parse(JSON.stringify(state.copiedBlocks));
      blocks = state.slides[state.slideIndex].blocks;
      if (state.copiedBlocks) {
        copiedBlocks.forEach((copiedBlock, index) => {
          blocks = {
            ...blocks,
            [`block${index + Date.now()}`]: {
              ...copiedBlock,
              position: {
                x: copiedBlock.position.x + 1,
                y: copiedBlock.position.y + 1
              }
            }
          };
        });
      } else blocks = { ...state.slides[parseInt(state.slideIndex)].blocks };

      return {
        ...state,
        slides: [
          ...state.slides.slice(0, state.slideIndex),
          {
            ...state.slides[state.slideIndex],
            blocks
          },
          ...state.slides.slice(parseInt(state.slideIndex) + 1)
        ]
      };

    case actionsTypes.SET_BLOCK:
      blocks = {
        ...state.slides[state.slideIndex].blocks,
        [action.blockKey]: action.block
      };
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(state.slideIndex + 1)
      ];

      return { ...state, slides };

    case actionsTypes.RESET_BLOCKS_SELECTED:
      return { ...state, blocksSelected: [] };

    case actionsTypes.SET_CONTROL_IS_PRESSED:
      return { ...state, controlIsPressed: action.isPressed };

    case actionsTypes.SET_IN_INPUT:
      return { ...state, inInput: action.inInput };

    case actionsTypes.SET_CONTENT:
      state.slides[state.slideIndex].blocks[action.index].content = action.content;
      return state;

    case actionsTypes.SET_HTML:
      state.slides[state.slideIndex].blocks[action.index].contentHtml =
          action.html;
      return state;

    case actionsTypes.SET_COLUMN_HTML:
      if (!action.hasAudio)
        state.slides[state.slideIndex].blocks[action.index].table[
            action.indexLine
            ][action.indexColumn].contentHtml = action.html;
      else
        state.slides[state.slideIndex].blocks[action.index].table[
            action.indexLine
            ][action.indexColumn][1].contentHtml = action.html;
      return state;

    case actionsTypes.SET_COLUMN_CONTENT:
      if (!action.hasAudio)
        state.slides[state.slideIndex].blocks[action.index].table[
            action.indexLine
            ][action.indexColumn].content = action.content;
      else
        state.slides[state.slideIndex].blocks[action.index].table[
            action.indexLine
            ][action.indexColumn][1].content = action.content;
      return state;

    case actionsTypes.UNDO:
      history = JSON.parse(localStorage.getItem('history'));
      if (history[history.length - 1]) {
        let stateAnterior = history[history.length - 1];
        history.pop();
        localStorage.setItem('history', JSON.stringify(history));
        addHistoryRedo();
        return stateAnterior;
      } else return state;

    case actionsTypes.REDO:
      history = JSON.parse(localStorage.getItem('history_redo'));
      if (history[history.length - 1]) {
        let stateAnterior = history[history.length - 1];
        history.pop();
        localStorage.setItem('history_redo', JSON.stringify(history));
        addHistory();
        return stateAnterior;
      } else return state;
    case actionsTypes.UPDATE_BLOCK_HISTORY:
      updateBlocksHistory();
      return state;

    default:
      return state;
  }
}
