import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  reset: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.RESET_CLICKABLES:
      return {
        ...state,
        reset: action.reset
      };
    default:
      return state;
  }
}
