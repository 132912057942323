import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  workplaces: [],
  workers: [],
  workerIndex: null,
  resetWorkplaceGameFunction: () => {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_WORKPLACE_GAME_STATE:
      return { ...state, ...action.state };

    default:
      return state;
  }
}
