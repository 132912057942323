import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  resetSnookerGameFunction: () => {},
  ballsCopy: [1, 2, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 20],
  selectedBall: null,
  imgSinuca: 'snooker'
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_RESET_SNOOKER_GAME_FUNCTION:
      return {
        ...state,
        resetSnookerGameFunction: action.resetSnookerGameFunction
      };

    case actionsTypes.SET_BALLS_COPY:
      return {
        ...state,
        ballsCopy: action.ballsCopy
      };

    case actionsTypes.SET_SELECTED_BALL:
      return {
        ...state,
        selectedBall: action.selectedBall
      };

    case actionsTypes.SET_IMG_SINUCA:
      return {
        ...state,
        imgSinuca: action.imgSinuca
      };

    default:
      return state;
  }
}
