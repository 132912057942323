import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  fastCardKey: '',
  lastCardSelected: '',
  cardClickedKey: '',
  cardAleatorio: {},
  noneBlock: {},
  cardIndex: null,
  flipCard: false,
  resetCards: false,
  changeImage: null,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_FAST_CARD_KEY:
      return {
        ...state,
        fastCardKey: action.fastCardKey
      };

    case actionsTypes.SET_LAST_CARD_SELECTED:
      return {
        ...state,
        lastCardSelected: action.lastCardSelected
      };

    case actionsTypes.SET_CARD_CLICKED:
      return {
        ...state,
        cardClickedKey: action.cardClickedKey
      };

    case actionsTypes.SET_CARD_ALEATORIO:
      return {
        ...state,
        cardAleatorio: {
          ...state.cardAleatorio,
          [action.cardKey]: action.cardAleatorio
        }
      };

    case actionsTypes.SET_NONE_BLOCK:
      return {
        ...state,
        noneBlock: {
          ...state.noneBlock,
          [action.cardKey]: action.state
        }
      };

    case actionsTypes.SET_CARD_INDEX:
      return {
        ...state,
        cardIndex: action.cardIndex
      };

    case actionsTypes.FLIP_CARD:
      return {
        ...state,
        flipCard: !state.flipCard
      };

    case actionsTypes.SET_CARD_RESET:
      return {
        ...state,
        resetCards: action.reset
      };

    case actionsTypes.RESET_CARD_REDUCER:
      return {
        ...INITIAL_STATE
      };

    case actionsTypes.SET_STATE:
      if (action.state?.CardReducer) return action.state.CardReducer;
      return state;

    case actionsTypes.CHANGE_IMAGE_CARD:
      return {
        ...state,
        changeImage: (action.changeImage === null ? null : !state.changeImage)
      };

    default:
      return state;
  }
}
