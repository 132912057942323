import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  contentScale: 1,
  larguraContent: null,
  alturaContent: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.INCREASE_CONTENT_SCALE:
      if (state.contentScale <= 1.7)
        return { ...state, contentScale: state.contentScale + 0.1 };
      return state;

    case actionsTypes.DECREASE_CONTENT_SCALE:
      if (state.contentScale > 1)
        return { ...state, contentScale: state.contentScale - 0.1 };
      return state;

    case actionsTypes.RESET_CONTENT_SCALE:
      return { ...state, contentScale: 1 };

    case actionsTypes.SET_SIZE_CONTENT:
      return {
        ...state,
        larguraContent: action.larguraContent,
        alturaContent: action.alturaContent
      };
    case actionsTypes.SET_STATE:
      if (action.state?.ContentReducer) return action.state.ContentReducer;
      return state;

    default:
      return state;
  }
}
