import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  parkingGameResetFunction: () => {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_PARKING_GAME_RESET_FUNCTION:
      return {
        ...state,
        parkingGameResetFunction: action.parkingGameResetFunction
      };

    default:
      return state;
  }
}
