import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  foodsSelected: [],
  randomFoods: [],
  correctFoods: [],
  foodGameResetFunction: () => {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_FOODS_SELECTED:
      return {
        ...state,
        foodsSelected: action.foods
      };

    case actionsTypes.SET_RANDOM_FOODS:
      return {
        ...state,
        randomFoods: action.foods
      };

    case actionsTypes.SET_CORRECT_FOODS:
      return {
        ...state,
        correctFoods: action.correctFoods
      };

    case actionsTypes.RESET_FOOD_GAME:
      return {
        ...INITIAL_STATE
      };

    case actionsTypes.SET_FOOD_GAME_RESET_FUNCTION:
      return {
        ...state,
        foodGameResetFunction: action.foodGameResetFunction
      };
    default:
      return state;
  }
}
