import axios from 'axios';

let refreshTokenPromise = null;

const apiLmsManager = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_LMS_MANAGER_API_URL_DEV
      : process.env.REACT_APP_LMS_MANAGER_API_URL
});

const ambiente = process.env.REACT_APP_AMBIENTE
  ? process.env.REACT_APP_AMBIENTE
  : 'ONLINE';

apiLmsManager.interceptors.request.use(async config => {
  if (ambiente === 'OFFLINE') return config;
  config.headers['Content-type'] = `application/json`;
  if (config.url !== '/login_check' && config.url !== '/empresa/logo') {
    const token = localStorage.getItem('@RockfellerToken');
    config.headers['Authorization'] = `Bearer ${token}`;
    /*token
      ? (config.headers['Authorization'] = `Bearer ${token}`)
      : (window.location.href = '/login');*/
  }

  return config;
});

/*function cleanStorage() {
  localStorage.removeItem('@RockfellerToken');
  localStorage.removeItem('@RockfellerRefreshToken');
  localStorage.removeItem('@JoyToken');
  localStorage.removeItem('@JoyRefreshToken');
  localStorage.removeItem('STUDENT_NAME');
  localStorage.removeItem('LOGO');
}*/

apiLmsManager.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    /*if (error.response.status === 401 && ambiente === 'OFFLINE')
      window.location.href = '/login';
    const originalRequest = error.config;
    if (error.response.config.url === '/login_check')
      return Promise.reject(error);

    if (
      error.response.status === 401 &&
      error.response.config.url === '/token/refresh'
    ) {
      localStorage.removeItem('@RockfellerToken');
      localStorage.removeItem('@RockfellerRefreshToken');
      //window.location.href = '/login';
    } else if (
      error.response.status === 401 &&
      localStorage.getItem('@RockfellerRefreshToken')
    ) {
      // Executa a atualização do token somente se não houver uma em andamento
      if (!refreshTokenPromise) {
        refreshTokenPromise = new Promise(async (resolve, reject) => {
          try {
            const refreshToken = await apiLmsManager.post('/token/refresh', {
              refresh_token: localStorage.getItem('@RockfellerRefreshToken')
            });
            if (refreshToken.status === 401 || refreshToken.status === 403) {
              localStorage.removeItem('@RockfellerToken');
              window.location.href = '/login';
            }
            localStorage.setItem('@RockfellerToken', refreshToken.data.token);
            localStorage.setItem(
              '@RockfellerRefreshToken',
              refreshToken.data.refresh_token
            );

            originalRequest._retry = true;

            originalRequest.headers.Authorization =
              'Bearer ' + refreshToken.data.token;

            localStorage.removeItem('LOGO');

            resolve(refreshToken);
            //return apiLmsManager(originalRequest);
          } catch (err) {
            reject(err);
            //cleanStorage();
            //window.location.href = '/login';
          } finally {
            refreshTokenPromise = null; // Limpa a promessa depois que terminar
          }
        });
      }

      // Aguarda a resolução da promessa de atualização do token
      await refreshTokenPromise;

      // Repete a solicitação que falhou depois de atualizar o token
      return apiLmsManager(error.config);
    } else if (error.response.status === 401 || error.response.status === 403) {
      cleanStorage();
      //window.location.href = '/login';

      //return apiLmsManager(originalRequest);
    }*/
    return Promise.reject(error);
  }
);

export default apiLmsManager;
