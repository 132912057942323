import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  optionsCards: [],
  cardsSelected: [],
  resetMemoryGameFunction: () => {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_MEMORY_GAME_OPTIONS_CARD:
      return {
        ...state,
        optionsCards: action.optionsCards
      };

    case actionsTypes.SET_MEMORY_GAME_CARDS_SELECTED:
      return {
        ...state,
        cardsSelected: action.cardsSelected
      };

    case actionsTypes.SET_RESET_MEMORY_GAME_FUNCTION:
      return {
        ...state,
        resetMemoryGameFunction: action.resetMemoryGameFunction
      };

    default:
      return state;
  }
}
