import React, { lazy, Suspense } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import useAuth from '../hooks/useAuth';
import { LoadingContainer } from './styles';

const AppLayout = lazy(() => import('./../layouts/App'));
const AuthLayout = lazy(() => import('./../layouts/Auth'));
const AulaLayout = lazy(() => import('./../layouts/Aula'));
const LiveAulaLayout = lazy(() => import('./../layouts/LiveAula'));

const Login = lazy(() => import('../pages/Auth/Login'));
const RecuperarSenha = lazy(() => import('../pages/Auth/RecuperarSenha'));
const AlterarSenha = lazy(() => import('../pages/Auth/AlterarSenha'));

const Dashboard = lazy(() => import('../pages/Dashboard'));
const RankingAlunos = lazy(() => import('../pages/Dashboard/RankingAlunos'));
const Cursos = lazy(() => import('../pages/Cursos'));
const Divisao = lazy(() => import('../pages/Cursos/Divisao'));
const Livro = lazy(() => import('../pages/Cursos/Livros'));
const Nivel = lazy(() => import('../pages/Cursos/Nivel'));
const Aula = lazy(() => import('../pages/Cursos/Aula'));
const ClassRoom = lazy(() => import('../pages/ClassRoom'));
const PerfilAluno = lazy(() => import('../pages/PerfilAluno'));
const InfoAula = lazy(() => import('../pages/PerfilAluno/InfoAula'));
const Provas = lazy(() => import('../pages/Provas'));
const Tarefas = lazy(() => import('../pages/Tarefas'));
const Corrigir = lazy(() => import('../pages/Provas/Corrigir'));
const Turmas = lazy(() => import('../pages/Turmas'));
const MaterialApoio = lazy(() => import('../pages/MaterialApoio'));
const NewTurma = lazy(() => import('../pages/Turmas/New'));
const UpdateTurma = lazy(() => import('../pages/Turmas/Update'));
const AlunosTurma = lazy(() => import('../pages/Turmas/Alunos'));
const Post = lazy(() => import('../pages/Post'));
const Posts = lazy(() => import('../pages/Posts'));

const offline = process.env.REACT_APP_AMBIENTE === 'OFFLINE';

const AppRoute = ({
  component: Component,
  layout: Layout,
  title,
  divisao = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Layout title={title}>
        <Component {...props} divisao={divisao}></Component>
      </Layout>
    )}
  ></Route>
);
function LoadingComponent() {
  return (
    <LoadingContainer>
      <BeatLoader color="var(--primary)" />
    </LoadingContainer>
  );
}
function Router() {
  const { isAuthenticated, loading } = useAuth();

  const href = window.location.href;
  const hrefSplited = href.split('/');
  const route = hrefSplited[3];

  if (loading) {
    return <LoadingComponent />;
  }

  if (
    !route.includes('login') &&
    !route.includes('recuperar-senha') &&
    !route.includes('alterar-senha') &&
    !isAuthenticated
  ) {
    hrefSplited.shift();
    hrefSplited.shift();
    hrefSplited.shift();
    window.location.href = `/login?path=${hrefSplited.join('/')}`;
    return;
  }

  return (
    <Suspense fallback={<LoadingComponent />}>
      <BrowserRouter>
        <AppRoute path="/login" exact layout={AuthLayout} component={Login} />
        <AppRoute
          path="/"
          exact
          layout={AppLayout}
          component={offline ? Cursos : Dashboard}
        />
        <AppRoute
          path="/ranking-alunos"
          exact
          layout={AppLayout}
          component={RankingAlunos}
        />

        <AppRoute
          path="/post/:postId"
          exact
          layout={AppLayout}
          component={Post}
        />

        <AppRoute path="/posts" exact layout={AppLayout} component={Posts} />

        <Route path="/perfil-aluno/:idAluno" exact component={PerfilAluno} />

        <Route
          path="/perfil-aluno/:idAluno/:idAula"
          exact
          component={InfoAula}
        />

        <AppRoute path="/cursos" exact layout={AppLayout} component={Cursos} />

        <AppRoute
          path="/:cursoId/divisao"
          exact
          layout={AppLayout}
          component={Divisao}
        />

        <AppRoute
          path="/:subdivisaoId/livro"
          exact
          layout={AppLayout}
          component={Livro}
        />

        <AppRoute
          path="/divisao/:subdivisaoId/livro"
          exact
          layout={AppLayout}
          component={Livro}
          divisao={true}
        />

        <AppRoute
          path="/:mapaId/nivel"
          exact
          layout={AppLayout}
          component={Nivel}
        />

        <AppRoute
          path="/live/aula/:slideId"
          exact
          layout={LiveAulaLayout}
          component={Aula}
        />

        <AppRoute
          path="/aula/:slideId"
          exact
          layout={AulaLayout}
          component={Aula}
        />

        <AppRoute
          exact
          path="/class-room"
          layout={AppLayout}
          component={ClassRoom}
          title="Minha sala de aula"
        />

        <AppRoute path="/provas" exact layout={AppLayout} component={Provas} />

        <AppRoute
          path="/provas/:provaId/:tipoAvaliacao/corrigir"
          exact
          layout={AppLayout}
          component={Corrigir}
        />

        <AppRoute
          path="/tarefas/:provaId/:tipoAvaliacao/corrigir"
          exact
          layout={AppLayout}
          component={Corrigir}
        />

        <AppRoute
          path="/tarefas"
          exact
          layout={AppLayout}
          component={Tarefas}
        />

        <AppRoute path="/turmas" exact layout={AppLayout} component={Turmas} />

        <AppRoute
          path="/turmas/new"
          exact
          layout={AppLayout}
          component={NewTurma}
        />

        <AppRoute
          path="/turmas/:id/update"
          exact
          layout={AppLayout}
          component={UpdateTurma}
        />
        <AppRoute
          path="/turmas/:id/alunos"
          exact
          layout={AppLayout}
          component={AlunosTurma}
        />
        <AppRoute
          path="/materiais-apoio"
          exact
          layout={AppLayout}
          component={MaterialApoio}
        />

        <AppRoute
          path="/recuperar-senha"
          exact
          layout={AuthLayout}
          component={RecuperarSenha}
        />

        <AppRoute
          path="/alterar-senha/:reset_token"
          exact
          layout={AuthLayout}
          component={AlterarSenha}
        />
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;
