import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  resetGlobeGameFunction: () => {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_RESET_GLOBE_GAME_FUNCTION:
      return {
        ...state,
        resetGlobeGameFunction: action.resetGlobeGameFunction
      };

    default:
      return state;
  }
}
