import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  showAbaExtra: false,
  aba: {},
  aula: null,
  slideIndex: 0,
  finishLesson: false,
  slides: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_SHOW_ABA_EXTRA:
      return {
        ...state,
        showAbaExtra: action.showAbaExtra
      };

    case actionsTypes.SET_ABA:
      return {
        ...state,
        aba: action.aba
      };

    case actionsTypes.SET_AULA_ABA:
      return {
        ...state,
        aula: action.aula
      };

    case actionsTypes.SET_SLIDE_INDEX_ABA:
      return {
        ...state,
        slideIndex: action.slideIndex
      };

    case actionsTypes.SET_FINISH_LESSON_ABA:
      return {
        ...state,
        finishLesson: action.finishLesson
      };

    case actionsTypes.SET_SLIDES_ABA:
      return {
        ...state,
        slides: action.slides
      };
    default:
      return state;
  }
}
