import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  isShowCursorMouse: false,
  microphone: null,
  showConfigMic: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_SHOW_CURSOR_MOUSE:
      return {
        ...state,
        isShowCursorMouse: action.isShowCursorMouse,
      };
    case actionsTypes.SET_MICROPHONE:
      return {
        ...state,
        microphone: action.microphone,
      };
    case actionsTypes.SET_SHOW_CONFIG_MICROPHONE:
      return {
        ...state,
        showConfigMic: action.showConfigMic,
      };

    default:
      return state;
  }
}
