import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  selectors: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_SELECTOR:
      return {
        ...state,
        selectors: {
          ...state.selectors,
          [action.selectorKey]: action.selectorValue
        }
      };

    default:
      return state;
  }
}
