import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  creditCard: {
    nomePortador: '',
    numero: '',
    cvv: '',
    validade: '',
    parcelas: 1,
    cpf: ''
  },
  paymentMethod: {
    creditCard: true,
    boleto: false
  },
  receivingMethod: {
    inHome: true,
    inSchool: false
  },
  total: 0,

  boleto: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_CREDIT_CARD:
      return {
        ...state,
        creditCard: action.creditCard
      };

    case actionsTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod
      };

    case actionsTypes.SET_RECEIVING_METHOD:
      return {
        ...state,
        receivingMethod: action.receivingMethod
      };

    case actionsTypes.SET_TOTAL:
      return {
        ...state,
        total: action.total
      };

    case actionsTypes.SET_BOLETO:
      return {
        ...state,
        boleto: action.boleto
      };

    default:
      return state;
  }
}
