const crumbs = JSON.parse(localStorage.getItem('_crumbs'));
const INITIAL_STATE = {
  crumbs: crumbs ? crumbs : [],
  previousCrumbs: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_CRUMBS':
      let previousCrumbs = state.crumbs;
      localStorage.setItem('_crumbs', JSON.stringify(action.crumbs));
      return { ...state, crumbs: action.crumbs, previousCrumbs };
    default:
      return state;
  }
}
