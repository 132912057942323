import styled from 'styled-components';

export const Container = styled.div`
  background: #fcfaf0;
  color: '#000';
  font-weight: bold;
  width: 400px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-right: 15px;
  padding: 20px 0;
  border: 1px solid #f1ba0b;
  z-index: 99999999999999999999999999999999999999999999999999999999999999;
`;
